import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"

Vue.use(VueRouter)

const isProd = process.env.NODE_ENV === "production"

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/vipps/fallback-result-page/:orderId",
    name: "vippsFallback",
    component: () => import("../views/VippsFallback.vue")
  },
  {
    path: "/:slug",
    name: "form",
    component: () => import(/* webpackChunkName: "form" */ "../views/Form.vue")
  },
  {
    path: "*",
    name: "not-found",
    component: () => import(/* webpackChunkName: "not-found" */ "../views/NotFound.vue")
  },
]

const router = new VueRouter({
  mode: "history",
  base: isProd ? process.env.BASE_URL : "/site",
  routes
})

export default router
