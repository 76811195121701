import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import "./plugins/appContext"
import bugsnag from "@bugsnag/js"
import bugsnagVue from "@bugsnag/plugin-vue"
import PortalVue from "portal-vue"

const bugsnagClient = bugsnag("77d4fe92c98e9d9cb6a7637596d1c07b")
bugsnagClient.use(bugsnagVue, Vue)

Vue.use(PortalVue)
Vue.config.productionTip = false
Vue.config.devtools = true

const globals = require.context("../../components/globals", true, /\.vue$/i)
globals.keys().map(key => Vue.component(key.split("/").pop().split(".")[0], globals(key).default))

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")
