<template>
  <forms-data-provider>
    <template slot-scope="{ data, loading }">
      <div class="container mx-auto my-8 sm:my-12 md:my-24 lg:max-w-xl">
        <headline class="mb-8 text-center">
          Velg bestillingsflyt
        </headline>

        <div
          v-if="loading"
          class="text-center py-3 text-muted">
          Laster alternativer...
        </div>
        <div v-else>
          <ul>
            <li
              v-for="(form,index) in data"
              :key="index"
              class="mt-2">
              <router-link
                :to="{ name: 'form', params: { slug: form.slug }}"
                class="block px-4 py-3 rounded border-2 cursor-pointer select-none radio-list-item">
                {{ form.title }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </forms-data-provider>
</template>

<script>
import FormsDataProvider from "../components/FormsDataProvider"
import EventBus from "@/utils/event-bus"

export default {
  components: {
    FormsDataProvider
  },

  mounted () {
    EventBus.$emit("init")
  }
}
</script>
