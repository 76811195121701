<script>
import axios from "axios"

export default {
  data () {
    return {
      loading: true,
      data: null
    }
  },

  mounted () {
    this.fetchForms()
  },

  methods: {
    fetchForms () {
      axios.get("/api/forms")
        .then(({ data }) => {
          this.loading = false
          this.data = data.data
        })
    }
  },

  render () {
    return this.$scopedSlots.default({
      data: this.data,
      loading: this.loading
    })
  }
}
</script>
