<template>
  <div
    id="app"
    class="min-h-full">
    <component :is="layout">
      <router-view :key="$route.fullPath" />
    </component>
  </div>
</template>

<script>
import DefaultLayout from "@/layouts/Default"
import MajaLayout from "@/layouts/Maja"
import HansLayout from "@/layouts/Hans"

export default {
  components: {
    DefaultLayout,
    MajaLayout
  },

  created () {
    this.theme = this.$app.slug || "default"
  },

  mounted() {
    document.body.classList.add(`theme-${this.theme}`)
  },

  data () {
    return {
      theme: "default"
    }
  },

  computed: {
    layout () {
      if (this.theme === "maja") {
        return MajaLayout
      }

      if (this.theme === "hans") {
        return HansLayout
      }

      return DefaultLayout
    }
  }
}
</script>

<style lang="scss">
  @import "../../assets/tailwind.css";
  @import "../../assets/typography/_default.scss";
  @import "../../assets/app.scss";
</style>
